import React from 'react'
import PropTypes from 'prop-types'
import ResponsiveCarousel from 'react-multi-carousel'
import styles from './timeline_carousel.module.css'
import TimelineItem, { itemPropTypes } from './timeline_item'

const Arrow = ({ className }) => (
  <svg className={className} viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill='#fafafa'
      d='M10.0001 3.33331L8.8251 4.50831L13.4751 9.16665L3.33344 9.16665L3.33344 10.8333L13.4751 10.8333L8.8251 15.4916L10.0001 16.6666L16.6668 9.99998L10.0001 3.33331Z'
    />
  </svg>
)

const TimelineCarousel = ({ items }) => {
  const RightArrow = ({ carouselState, ...rest }) => (
    <div className={styles.rightArrow}>
      <div className={styles.gradient} />
      <button type='button' aria-label='Right arrow' className={styles.arrowButton} {...rest}>
        <Arrow className={styles.arrowIcon} />
      </button>
    </div>
  )
  const LeftArrow = ({ carouselState, ...rest }) => (
    <div className={styles.leftArrow}>
      <div className={styles.gradient} />
      <button type='button' aria-label='Left arrow' className={styles.arrowButton} {...rest}>
        <Arrow className={styles.arrowIcon} />
      </button>
    </div>
  )
  const Line = () => <div className={styles.line} />

  return (
    <ResponsiveCarousel
      responsive={{
        large: { breakpoint: { min: 771, max: Infinity }, items: 4, slidesToSlide: 4 },
        medium: { breakpoint: { min: 561, max: 770 }, items: 3, slidesToSlide: 3 },
        small: { breakpoint: { min: 0, max: 560 }, items: 1, slidesToSlide: 1 }
      }}
      containerClass={styles.container}
      sliderClass={styles.slider}
      itemClass={styles.card}
      customRightArrow={<RightArrow />}
      customLeftArrow={<LeftArrow />}
      customButtonGroup={<Line />}
    >
      {items.map((item, index) => (
        <div className={styles.card} key={`card-${index}`}>
          <TimelineItem item={item} showTimePeriod={items[index]?.timePeriod !== items[index - 1]?.timePeriod} />
        </div>
      ))}
    </ResponsiveCarousel>
  )
}

TimelineCarousel.propTypes = {
  items: PropTypes.arrayOf(itemPropTypes).isRequired
}

export default TimelineCarousel
