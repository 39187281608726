import React from 'react'
import { AnchorWithArrow } from '@envato/ui'
import PropTypes from 'prop-types'
import { useAnchorClickTracking } from '@envato/google-analytics'
import styles from './timeline_item.module.css'

const TimelineItem = ({ item, showTimePeriod = false }) => (
  <>
    <div className={styles.timePeriod}>
      {showTimePeriod && (
        <>
          <span>{item.timePeriod}</span>
          <div className={styles.datapoint} />
        </>
      )}
    </div>
    <div className={styles.image}>
      <img src={item.imgSrc} alt={item.imgAlt} loading='lazy' />
    </div>
    <span className={styles.itemText}>{item.text}</span>
    {item.linkHref ? (
      <div className={styles.link}>
        <TrackedItemLink href={item.linkHref} timePeriod={item.timePeriod} index={0} linkText={item.linkText} />
      </div>
    ) : null}
  </>
)

export const itemPropTypes = PropTypes.shape({
  timePeriod: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  linkHref: PropTypes.string
})

TimelineItem.propTypes = {
  item: itemPropTypes,
  showTimePeriod: PropTypes.bool
}

function TrackedItemLink({ href, timePeriod, index, linkText }) {
  const trackClick = useAnchorClickTracking('item_link', `year_${timePeriod}_index_${index}`)
  return (
    <AnchorWithArrow href={href} onClick={trackClick} target='_blank'>
      {linkText}
    </AnchorWithArrow>
  )
}

export default TimelineItem
