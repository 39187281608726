import React from 'react'
import CenteredSectionWrapper from '../centered_section_wrapper/centered_section_wrapper'
import TimelineCarousel from '../timeline_carousel/timeline_carousel'
import items from './timeline_items'
import styles from './timeline_section.module.css'

const TimelineSection = () => (
  <div className={styles.root}>
    <CenteredSectionWrapper>
      <div className={styles.text}>
        <div className={styles.preText}>Our History</div>
        <h3 className={styles.title}>
          It’s been quite a journey since our early beginnings in a Sydney garage to having offices in Melbourne and
          Guadalajara.
        </h3>
      </div>
    </CenteredSectionWrapper>
    <TimelineCarousel items={items} />
  </div>
)

export default TimelineSection
