import activeden from '../../images/timeline_images/activeden.png'
import tuts from '../../images/timeline_images/tuts.png'
import themesandaudio from '../../images/timeline_images/themesandaudio.png'
import graphicsandvideo from '../../images/timeline_images/graphicsandvideo.png'
import office from '../../images/timeline_images/office.png'
import code from '../../images/timeline_images/code.png'
import photos from '../../images/timeline_images/photos.png'
import elite from '../../images/timeline_images/elite.png'
import kriesi from '../../images/timeline_images/kriesi.png'
import newoffice from '../../images/timeline_images/newoffice.png'
import studio from '../../images/timeline_images/studio.png'
import gptw from '../../images/timeline_images/gptw.png'
import earnings from '../../images/timeline_images/earnings.png'
import byeActiveden from '../../images/timeline_images/byeActiveden.png'
import women from '../../images/timeline_images/women.png'
import ten from '../../images/timeline_images/ten.png'
import community from '../../images/timeline_images/community.png'
import elements from '../../images/timeline_images/elements.png'
import exporter from '../../images/timeline_images/exporter.png'
import international from '../../images/timeline_images/international.png'
import fivehundred from '../../images/timeline_images/fivehundred.png'
import tenMillion from '../../images/timeline_images/tenMillion.png'
import placeit from '../../images/timeline_images/placeit.png'
import apprentice from '../../images/timeline_images/apprentice.png'
import payouts from '../../images/timeline_images/payouts.png'
import foundation from '../../images/timeline_images/foundation.png'
import avada from '../../images/timeline_images/avada.png'
import milkshake from '../../images/timeline_images/milkshake.png'
import twentytwenty from '../../images/timeline_images/twentytwenty.png'
import sevenFiftyMillion from '../../images/timeline_images/sevenFiftyMillion.png'
import seventhGptw from '../../images/timeline_images/seventhGptw.png'
import bigStudio from '../../images/timeline_images/bigStudio.png'
import hichame from '../../images/timeline_images/hichame.jpg'
import earningsGraph from '../../images/timeline_images/earningsgraph.jpg'
import independentOnline from '../../images/timeline_images/independentOnline.jpg'
import newZealand from '../../images/timeline_images/newZealand.jpg'
import tutsGoesFree from '../../images/timeline_images/tutsGoesFree.jpg'
import carbonNeutral from '../../images/timeline_images/carbonNeutral.jpg'

export default [
  {
    timePeriod: '2006',
    imgAlt: 'ActiveDen becomes Envato’s first digital assets marketplace.',
    imgSrc: activeden,
    text: 'Collis, Cyan and Jun Rung launch Active Den'
  },
  {
    timePeriod: '2007',
    imgAlt: 'Our courses and tutorials hub has helped thousands of people learn and earn online.',
    imgSrc: tuts,
    text: 'PSD Tuts (now Tuts+) and Freelance Switch launch'
  },
  {
    timePeriod: '2008',
    imgAlt: 'Did you know that ThemeForest was once named one of the top 100 websites in the world (Alexa, 2014)?',
    imgSrc: themesandaudio,
    text: 'ThemeForest and AudioJungle go live'
  },
  {
    timePeriod: '2009',
    imgAlt:
      'Our marketplaces for graphic templates and video content are home to some of the world’s leading creators in these fields.',
    imgSrc: graphicsandvideo,
    text: 'GraphicRiver and VideoHive launch'
  },
  {
    timePeriod: '2009',
    imgAlt: 'From little things, big things grow!',
    imgSrc: office,
    text: 'Envato moves out of its first home in a Sydney garage and into its first headquarters in Melbourne, with room for 30 staff'
  },
  {
    timePeriod: '2010',
    imgAlt: 'CodeCanyon’s library of plugins and scripts has helped web designers around the globe.',
    imgSrc: code,
    text: 'CodeCanyon launches'
  },
  {
    timePeriod: '2011',
    imgAlt: 'PhotoDune’s library of stock photos contributes to a global industry of more than 350 million photos!',
    imgSrc: photos,
    text: 'PhotoDune and 3DOcean launch'
  },
  {
    timePeriod: '2011',
    imgAlt: 'It’s just another way we recognise our global author community for their creative contributions.',
    imgSrc: elite,
    text: 'The Elite Author Program launches, recognizing the efforts of Envato’s community of authors'
  },
  {
    timePeriod: '2011',
    imgAlt: 'More than 140 authors have made at least $1m USD with Envato.',
    imgSrc: kriesi,
    text: 'We celebrate Kriesi as the first author to reach $1 million USD in sales'
  },
  {
    timePeriod: '2011',
    imgAlt: 'Fun fact: Envato’s current Melbourne HQ used to be a school.',
    imgSrc: newoffice,
    text: 'Envato moves again, this time to its current HQ with room for more than 200 staff!'
  },
  {
    timePeriod: '2013',
    imgAlt: 'Envato Studio is regularly named one of the world’s leading freelance marketplaces.',
    imgSrc: studio,
    text: 'Microlancer (now Envato Studio) launches'
  },
  {
    timePeriod: '2014',
    imgAlt: 'We’re proud and humbled to be known as one of Australia’s best places to work.',
    imgSrc: gptw,
    text: 'Envato is named ‘Australia’s Coolest Tech Company’ and is named one of Australia’s best workplaces in the annual Great Place to Work Survey',
    linkText: 'Read more',
    linkHref:
      'https://www.news.com.au/finance/work/careers/envato-named-australias-coolest-tech-company-in-jobadvisor-competition/news-story/1a749cce439064790a5cb53d6533114a'
  },
  {
    timePeriod: '2014',
    imgAlt: 'We’re working hard to reach our ambitious target of $1 billion USD in total author earnings. ',
    imgSrc: earnings,
    text: 'Envato community earnings surpass $200 million USD'
  },
  {
    timePeriod: '2015',
    imgAlt: 'All good things come to an end…',
    imgSrc: byeActiveden,
    text: 'Envato’s first marketplace ‘ActiveDen’ closes, after selling more than 1 million items'
  },
  {
    timePeriod: '2015',
    imgAlt:
      'Being an advocate for diversity and inclusion in the tech sector is something we are incredibly passionate about.',
    imgSrc: women,
    text: "Envato is named Australia's Coolest Company for Women"
  },
  {
    timePeriod: '2016',
    imgAlt: 'Yes, we definitely had cake to celebrate ten years in business! ',
    imgSrc: ten,
    text: 'Envato turns 10'
  },
  {
    timePeriod: '2016',
    imgAlt: 'Our community now totals more than 7 million people globally. ',
    imgSrc: community,
    text: 'Total community earnings are now more than $400 million USD. Envato Market now surpasses two million active buyers every year'
  },
  {
    timePeriod: '2016',
    imgAlt:
      'Envato Elements continues to lead the way as the number one unlimited subscription service for digital creatives.',
    imgSrc: elements,
    text: 'Envato Elements launches, providing an unlimited download subscription service for creative assets',
    linkHref: 'https://elements.envato.com/',
    linkText: 'Visit Envato Elements'
  },
  {
    timePeriod: '2016',
    imgAlt: 'We were also named the Digital Technologies Award winner at the same event!',
    imgSrc: exporter,
    text: 'Envato is named Australian Exporter of the year',
    linkHref: 'https://www.exportawards.gov.au/Winners/2016',
    linkText: 'Read more'
  },
  {
    timePeriod: '2017',
    imgAlt: 'Did you know that our first sale was made to a customer overseas?!',
    imgSrc: international,
    text: 'Envato’s success as one of Australia’s top international businesses is recognized again, as it is named the best Creative Industries Exporter',
    linkHref: 'https://www.exportawards.gov.au/Winners/2017',
    linkText: 'Read more'
  },
  {
    timePeriod: '2017',
    imgAlt: 'The quest for $1 billion USD continues!',
    imgSrc: fivehundred,
    text: 'Community earnings now total $500 million USD'
  },
  {
    timePeriod: '2018',
    imgAlt:
      'The United States, Russia, Ukraine, India and Romania are home to the largest numbers of our author community.',
    imgSrc: tenMillion,
    text: 'Our global creative community swells to 10 million members'
  },
  {
    timePeriod: '2018',
    imgAlt: 'Hola Guadalajara!',
    imgSrc: placeit,
    text: 'Mexico-based Placeit joins the Envato family',
    linkHref:
      'https://www.smartcompany.com.au/startupsmart/news/envato-acquires-placeit-co-founder-collis-taeed-shared-vision-behind-move/',
    linkText: 'Read more'
  },
  {
    timePeriod: '2018',
    imgAlt:
      'The 12 month Developer Apprentice Program provides a mix of classroom learning with plenty of on-the-job experience.',
    imgSrc: apprentice,
    text: 'We launch a Developer Apprentice Program to improve diversity and inclusion in our local tech sector',
    linkHref:
      'https://www.afr.com/technology/envato-tackles-talent-and-diversity-shortages-with-apprenticeships-20180627-h11xpo',
    linkText: 'Read more'
  },
  {
    timePeriod: '2018',
    imgAlt: 'We now have more than 2 million active customers every year.',
    imgSrc: payouts,
    text: 'We officially sell our 50 millionth item, passing $700 million USD in total author payouts in the process'
  },
  {
    timePeriod: '2019',
    imgAlt:
      'The Envato Foundation has partnered with Ganbina, Yalari, Indigitek and the Indigenous Literacy Foundation.=',
    imgSrc: foundation,
    text: 'The Envato Foundation is launched to help tackle Indigenous disadvantage in education ',
    linkHref: 'https://envatofoundation.org/',
    linkText: 'Read more'
  },
  {
    timePeriod: '2019',
    imgAlt: 'It’s one of the most popular WordPress themes of all time!',
    imgSrc: avada,
    text: 'ThemeFusion’s Avada WordPress theme reaches 500,000 sales',
    linkHref: 'https://press.envato.com/172282-one-of-the-world-s-most-popular-wordpress-themes-hits-new-milestone',
    linkText: 'Read more'
  },
  {
    timePeriod: '2019',
    imgAlt: 'Better yet, the Milkshake app is free on both iOS and Android',
    imgSrc: milkshake,
    text: 'Milkshake, a website-building app for Instagrammers, is released to the world!',
    linkHref: 'https://milkshake.app/',
    linkText: 'Visit Milkshake'
  },
  {
    timePeriod: '2019',
    imgAlt: 'Twenty20 have a library of more than 50 million photos from more than 300,000 photographers worldwide.',
    imgSrc: twentytwenty,
    text: 'Envato acquires LA-based Twenty20, one of the world’s leading authentic stock photography communities. We now have 600 staff globally',
    linkHref: 'https://www.twenty20.com/',
    linkText: 'Visit Twenty20'
  },
  {
    timePeriod: '2019',
    imgAlt: 'The Envato Elements library also passed the 1 million item milestone!',
    imgSrc: sevenFiftyMillion,
    text: 'Community earnings reach $750 million USD. More than 140 authors have now earned over a million dollars each',
    linkHref:
      'https://au.finance.yahoo.com/news/140-people-made-14-million-each-using-this-melbournemade-website-210200614.html',
    linkText: 'Read more'
  },
  {
    timePeriod: '2019',
    imgAlt: 'It’s lucky our courtyard is so big!',
    imgSrc: seventhGptw,
    text: 'For the sixth straight year, Envato is named one of Australia’s best places to work, placing in the top ten (7th) for the first time! ',
    linkHref: 'https://press.envato.com/181790-great-place-to-work-results-honour-envato-s-consistency',
    linkText: 'Read more'
  },
  {
    timePeriod: '2019',
    imgAlt:
      'Our freelance marketplace is now home to more than 500 digital experts offering more than 3000 creative services.',
    imgSrc: bigStudio,
    text: 'Envato Studio reaches $10 million USD in completed work',
    linkHref: 'https://studio.envato.com/',
    linkText: 'Read more'
  },
  {
    timePeriod: '2020',
    imgAlt: 'Envato CEO Hichame Assi',
    imgSrc: hichame,
    text: 'Envato appoints Hichame Assi as its second-ever CEO',
    linkHref: 'https://www.envato.com/blog/new-envato-ceo-hichame-assi/',
    linkText: 'Read more'
  },
  {
    timePeriod: '2020',
    imgAlt: 'Illustration of human character jumping from one increasing pillar to the next',
    imgSrc: earningsGraph,
    text: 'Envato reaches $1 billion USD in total community earnings',
    linkHref: 'https://www.envato.com/blog/1-billion-envato-author-community-milestone/',
    linkText: 'Read more'
  },
  {
    timePeriod: '2021',
    imgAlt: 'a16z Marketplace 100 Ranking Header',
    imgSrc: independentOnline,
    text: 'Envato named Australia’s top independent online marketplace',
    linkHref: 'https://www.envato.com/blog/envato-named-australias-top-independent-online-marketplace/',
    linkText: 'Read more'
  },
  {
    timePeriod: '2021',
    imgAlt: 'Envato’s global growth continues with new NZ presence',
    imgSrc: newZealand,
    text: 'Envato officially establishes a presence in New Zealand',
    linkHref: 'https://www.envato.com/blog/envato-in-new-zealand/',
    linkText: 'Read more'
  },
  {
    timePeriod: '2022',
    imgAlt: 'Tuts+ Goes Free',
    imgSrc: tutsGoesFree,
    text: 'Envato Tuts+ goes completely free',
    linkHref: 'https://www.envato.com/blog/envato-makes-over-1300-premium-online-courses-free/',
    linkText: 'Read more'
  },
  {
    timePeriod: '2022',
    imgAlt: 'Envato Goes Carbon Neutral in Partnership with Trace',
    imgSrc: carbonNeutral,
    text: 'Envato is recognised officially as a carbon neutral business',
    linkHref: 'https://www.envato.com/blog/envato-carbon-neutral/',
    linkText: 'Read more'
  }
].reverse()
